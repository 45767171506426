import React, { useEffect, useMemo } from "react";
import styles from "./styles.module.scss";
import { Theme } from "src/theme/Theme";
import { useTheme } from "src/theme/ThemeProvider";
import { TFunction, useTranslation } from "react-i18next";
import {
  Focusable,
  FocusableSection,
  useForceFocusOnMount,
  useSpatialNavigation,
} from "@blacknut/spatialnav-sdk/dist";
import { Link, useHistory } from "react-router-dom";
import { Config, User } from "@blacknut/javascript-sdk/dist";
import { useSelector } from "react-redux";
import { State } from "@blacknut/react-client-core/lib";
import { ReactComponent as ID } from "../../assets/dist/ic_id.svg";
import { ReactComponent as IDActive } from "../../assets/dist/ic_id_active.svg";
import { useHeader } from "src/components/Header/HeaderProvider";
import HeaderBackButton from "src/components/Header/HeaderBackButton";

const makeItems = (
  t: TFunction,
  theme: Theme,
  themes: string[],
  user?: User,
  config?: Config,
) => {
  const items = [
    {
      label: t("buttons.speedTest"),
      icon: theme.images.Speedtest && <theme.images.Speedtest />,
      iconActive: theme.images.SpeedtestActive && <theme.images.SpeedtestActive />,
      to: "/account/speedtest",
    },
  ];
  if (themes.length > 1 && config?.features?.themes !== false) {
    items.push({
      label: t("personalization.title"),

      icon: theme.images.LightTheme && <theme.images.LightTheme />,
      iconActive: theme.images.LightThemeActive && <theme.images.LightThemeActive />,
      to: "/account/personalization",
    });
  }

  items.push({
    label: t("buttons.accountId", { appName: process.env.REACT_APP_APP_NAME }),
    icon: <ID />,
    iconActive: <IDActive />,
    to: "/account/id",
  });

  if (user?.powerUser) {
    items.push({
      label: "Nerd settings",
      icon: theme.images.Settings && <theme.images.Settings />,
      iconActive: theme.images.SettingsActive && <theme.images.SettingsActive />,
      to: "/account/settings/advanced",
    });
  }
  return items;
};
const SettingsPage = () => {
  const { theme, themes } = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const { user, config } = useSelector((state: State) => state.globalState);
  const items = useMemo(
    () => makeItems(t, theme, themes, user, config),
    [t, theme, themes, user, config],
  );

  const { setTitle: setHeaderTitle, setHeaderLeft, setHeaderRight } = useHeader();
  useEffect(() => {
    setHeaderTitle(t("settings.title"));
    setHeaderLeft(<HeaderBackButton title={t("settings.title")} />);
    setHeaderRight(undefined);
  }, [setHeaderLeft, setHeaderRight, setHeaderTitle, t]);

  const { resume: resumeSpatialNav } = useSpatialNavigation();
  useEffect(() => {
    //Defer resume after app.router has been paused it
    setTimeout(() => {
      resumeSpatialNav();
    }, 200);
  }, [resumeSpatialNav]);

  return (
    <FocusableSection className={styles.container} focusKey="settings">
      <ul className={styles.items}>
        {items.map((item) => (
          <Focusable
            className={styles.card}
            component="li"
            key={item.to}
            onPressEnter={() => {
              history.push(item.to);
            }}
            onClick={() => {
              history.push(item.to);
            }}
          >
            <Link className={styles.link} to={item.to}>
              {item.icon && item.icon}
              {item.iconActive && item.iconActive}
              <span>{item.label}</span>
            </Link>
          </Focusable>
        ))}
      </ul>
    </FocusableSection>
  );
};
export default SettingsPage;
